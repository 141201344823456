import React from "react";
import './servicios.css';

function Servicios(props){
    return (
        
        <div className='servicios-contenedor'>                
                    <img className='servicios-imagen'
                    src={props.imagen} 
                    alt='Foto hidraulica'/>
                    <div className='servicios-texto-contenedor'>
                        <p className='servicios-titulo'>
                            <strong>{props.titulo}</strong>
                        </p>
                        <p className='servicios-texto'>{props.texto}</p>
                    </div>
                </div>
                
           
    )
}

export default Servicios;