import React from "react";
import '../navbar/navbar.css';
import logo from '../imagenes/hidraulica-logo-2.png';

function NavBar(){    
    return (
        <div className='navbar-contenedor'>
            <div className='logo'>               
            <a href='index.html'><img src={logo} alt="Hidraulica logo" /></a>
            </div>        
            <nav>                        
            <ul className='navLinks'>
                <li><a href="#servicios-id">Servicios</a></li>
                <li><a href="#ubicacion-id">Ubicación</a></li>
                <li><a href="#contacto-id">Contacto</a></li>
            </ul>
            </nav>
        </div>
    );
}

export default NavBar;