import React from "react";  
import './contacto.css';
import { FaWhatsapp, FaFacebook, FaInstagram, FaPhoneAlt } from "react-icons/fa";


function Contacto(){
    return (      
        <div className='contacto-contenedor'> 
       <p><FaPhoneAlt className='icono1'/>Teléfono: 3781-441054</p>
        <div className='contacto-iconos'>
       <a target="_blank" href="https://wa.me/543781441054"><FaWhatsapp className='icono' /></a>
       <a target="_blank" href="https://www.instagram.com/hidraulica_capital/?igsh=MThhc2dpMWE3MGJrdA%3D%3D"><FaInstagram className='icono'/></a>
       <a target="_blank" href="https://www.facebook.com/profile.php?id=61555198653447&mibextid=ZbWKwL"><FaFacebook className='icono'/></a>
        </div>
        </div> 
    )
}

export default Contacto;