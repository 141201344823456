import React from "react";
import './footer.css';

function Footer(){
    return (
        <div className='footer-contenedor'>
            <p>Creado por <a href="https://www.instagram.com/japtoos/">Alejandro Vallejos</a></p>
        </div>
    );
}

export default Footer;