import './App.css';
import NavBar from './navbar/navbar.jsx';
import Servicios from './Servicios/servicios.jsx';
//import Ubicacion from './Ubicacion/ubicacion.jsx';
import Contacto from './Contacto/contacto.jsx';
import Footer from './Footer/footer.jsx';

function App() {
  return (
    <div className="App">
    <div className="contenedor-principal">
        
        <NavBar />
        <h1 className='titulos-principal-1' id='servicios-id'>Servicios</h1>
        <div className='servicios'>
        <Servicios
          titulo='Reparaciones'
          texto= ''
          imagen={require(`./imagenes/hidraulica-reparacion.png`)}
          
        />
        <Servicios          
          titulo='Cremalleras'
          texto= ''
          imagen={require(`./imagenes/hidraulica-reparacion-2.jpg`)}
        />
        <Servicios
          titulo='Bomba hidráulica'
          texto= ''
          imagen={require(`./imagenes/hidraulica-reparacion-3.jpeg`)}
        />

        <Servicios 
         titulo='Caja de dirección'
         texto=''
         imagen={require(`./imagenes/hidraulica-reparacion-4.jpg`)}
        />
        <Servicios 
         titulo='Gatos hidráulicos'
         texto=''
         imagen={require(`./imagenes/hidraulica-reparacion-5.jpeg`)}
        />
        <Servicios 
         titulo='Tren delantero'
         texto=''
         imagen={require(`./imagenes/hidraulica-reparacion-6.jpg`)}
        />
        </div>
        

        {/* <h1 className='titulos-principal' id='ubicacion-id'>Ubicación</h1>
        <Ubicacion /> */}

        <h1 className='titulos-principal' id='contacto-id'>Contacto</h1>
        <Contacto />

        <Footer className='footer'/>

    </div>
    </div>
  );
}

export default App;
